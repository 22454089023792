'use client'
import { type FC, useState } from 'react'
import { useRouter } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { useSession, useTranslator, useToast, useModal } from '@/hooks'
import { autoComplete, inputPattern, cookie } from '@/utils'
import { Button, Input } from '..'
import { MODALS } from '@/context'

type TypeFormInputs = {
  email: string,
  password: string
}

export const FormAuthLogin: FC = () => {
  const { register, handleSubmit, formState: { errors: formErrors } } = useForm<TypeFormInputs>()
  const { push } = useRouter()
  const { t } = useTranslator()
  const { setUser } = useSession()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const { toggle:toggleForgotPassword } = useModal(MODALS.FORGOT_PASSWORD)
  const { addToast } = useToast()

  const onSubmit:SubmitHandler<TypeFormInputs> = async (formData) => {
    setButtonDisabled(true)
    try {
      const res = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      if (!res.ok) {
        if(res.status === 422) {
          const data = await res.json()
          // TODO/PENDING add iteration for multiple errors
          const errorMessage = data?.errors?.[0]?.message
          if (!errorMessage) throw new Error('FormAuthLogin submit failed')
          addToast({
            message: errorMessage,
            type: 'error'
          })
        }
        setButtonDisabled(false)
      }

      if(res.status === 200) {
        addToast({
          message: 'Logging in...',
          type: 'loading'
        })

        const data = await res.json()
        // Vets data stored in localStorage
        localStorage.setItem(`dy_virbac_user_${data.user.id}`, JSON.stringify([]))

        // State update
        setUser({ ...data.user, token: data.token })

        // Cookie without vets data (too much data to store in cookie)
        const incomingCookie = { ...data }
        incomingCookie.user.vet = data.user.vets?.[0]
        incomingCookie.user.vets = data.user.vets?.length
        cookie.set('DY_VIRBAC_USER', JSON.stringify(incomingCookie), { maxAge: getMaxAge(data.exp) })

        // const href = privateRedirectByRole[data.user.role](incomingCookie.user.vet?.id, incomingCookie.user.vets > 1)
        const href = '/'

        push(`${href}?timestamp${new Date().getTime()}`)
        setButtonDisabled(false)
      }

      setButtonDisabled(false)
    } catch(e) {
      console.warn('Login submit - Catch e', e)
      addToast({
        message: 'Could not log in, please try again later or contact support.',
        type: 'error'
      })
      setButtonDisabled(false)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input type='email' name='email' register={register} label={t('form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} required={{
          required: t('form.errors.empty') as string,
          pattern: {
            value: inputPattern.email,
            message: t('form.errors.pattern_email') as string
          }
        }} error={formErrors.email} bgColor={'background_login'} />
        <Input type='password' name='password' register={register} label={t('form.fields.password') as string} placeholder='password' autoComplete={autoComplete.password.current} icon={'password'} bgColor={'background_login'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} />
        <Button type='submit' disabled={buttonDisabled}>Log in</Button>
        <ButtonReset type='button' onClick={toggleForgotPassword}>I forgot my password</ButtonReset>
      </Form>
    </>
  )
}

const Form = styled.form`
  button {
    &[type='submit'] {
      margin: 40px auto 20px;
    }
  }
`

const ButtonReset = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blue};
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 25px;
  margin: 0 auto;
  text-decoration: none;
`

export const getMaxAge = (dateString:string):number => {
  // Regex to detect if the dateString is in ISO format "YYYY-MM-DDTHH:mm:ssZ" or similar
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(.\d+)?(Z|([+-]\d{2}:\d{2})))?$/

  let isoFormattedString

  if (isoRegex.test(dateString)) {
    // Use as is if already ISO 8601 format
    isoFormattedString = dateString
  } else {
    // Assume dateString is in "MM-DD-YYYY HH:mm" format
    const [datePart, timePart] = dateString.split(' ')
    const [month, day, year] = datePart.split('-')
    isoFormattedString = `${year}-${month}-${day}T${timePart}`
  }

  const date = new Date(isoFormattedString)
  const currentTime = new Date()
  const timeDifferenceMilliseconds = date.getTime() - currentTime.getTime()

  const maxAgeSeconds = Math.floor(timeDifferenceMilliseconds / 1000)
  return maxAgeSeconds
}

'use client'
import { useEffect, useState, type FC } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { jwtDecode } from 'jwt-decode'
import styled from 'styled-components'
import { useTranslator, useToast } from '@/hooks'
import { autoComplete, inputPattern, validate } from '@/utils'
import { Button, Input, Checkbox, Link } from '..'
import { getP20, mq, vw } from '@/styles'

type Props = {
}

type TypeFormInputs = {
  password: string
  comercial_name: string
  comercial_address: string
  comercial_postal_code: string
  comercial_province: string
  comercial_city: string
  comercial_phone: string
  automatic_billing?: boolean
  fiscal_name: string
  fiscal_address: string
  fiscal_postal_code: string
  fiscal_province: string
  fiscal_city: string
  fiscal_cif: string
  fiscal_iban: string
  communications_allowed?: boolean
  privacy_policy_accepted?: boolean
}

export const FormAuthSignUp: FC<Props> = ({ }) => {
  const { register, handleSubmit, formState: { errors: formErrors }, watch } = useForm<TypeFormInputs>({ mode: 'onChange'})
  const { push } = useRouter()
  const { t } = useTranslator()
  const { addToast } = useToast()
  const searchParams = useSearchParams()
  const token = searchParams.get('token')
  const [decodedUser, setDecodedUser] = useState(null)
  const [data, setData] = useState(null)
  const [step, setStep] = useState(0)

  // const communicationsAllowed = watch('communications_allowed')
  const password = watch('password')
  const privacyPolicyAccepted = watch('privacy_policy_accepted')

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData) => {
    const body = {
      password: formData.password,
      token,
      role: decodedUser?.role
    }

    if (decodedUser?.role === 'vet_manager' && decodedUser?.onboarding) {
      body['vet_attributes'] = {
        comercial_name: formData.comercial_name,
        comercial_address: formData.comercial_address,
        comercial_postal_code: formData.comercial_postal_code,
        comercial_province: formData.comercial_province,
        comercial_city: formData.comercial_city,
        comercial_phone: formData.comercial_phone,
        automatic_billing: formData.automatic_billing,
        fiscal_name: formData.fiscal_name,
        fiscal_address: formData.fiscal_address,
        fiscal_postal_code: formData.fiscal_postal_code,
        fiscal_province: formData.fiscal_province,
        fiscal_city: formData.fiscal_city,
        fiscal_cif: formData.fiscal_cif,
        fiscal_iban: formData.fiscal_iban,
        communications_allowed: formData.communications_allowed,
      }
    }

    try {
      const res = await fetch('/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })

      if (!res.ok) throw new Error('FormAuthLogin submit failed')
      if (res.status === 200) {
        addToast({
          message: 'Redirecting to login page',
          type: 'loading'
        })

        push('/login')
      }
    } catch (e) {
      console.warn('Login submit - Catch e', e)
      addToast({
        message: 'Sign up failed, please contact support',
        type: 'error'
      })
    }
  }

  useEffect(() => {
    const payload: { user?: any, vet?: any, exp?: number } = jwtDecode(token)
    setDecodedUser(payload?.user)
    console.log('🟡 payload', payload)

    if(payload?.user?.role === 'vet_manager') {
      setData(payload?.vet)
    }
  }, [token])

  return (
    <>
      <hgroup>
        <h1>Bienvenido/a a Virbac</h1>
        {step === 0 && <p>Para acceder al administrador de pedidos, por favor elige una contraseña:</p>}
        {step === 1 && <p>Revisa que tus datos comerciales estén correctos:</p>}
      </hgroup>
      <Form onSubmit={handleSubmit(onSubmit)} $largeWidth={decodedUser?.role === 'vet_manager' && (step === 1 || step === 2)}>
        <Input className={step === 1 ? 'hidden' : ''} type='password' name='password' register={register} label={t('form.fields.new_password') as string} placeholder='password' autoComplete={autoComplete.password.new} icon={'password'} bgColor={'background_login'} required={{
          required: true,
          pattern: {
            value: inputPattern.password,
            message: t('form.errors.pattern_password') as string
          }
        }} error={formErrors.password}/>
        {step === 1 && <Button className='go-back' type='button' onClick={() => setStep(0)}>{t('commons.actions.go_back')}</Button>}
        {decodedUser?.onboarding && decodedUser?.role === 'vet_manager' && step === 1 && <>
          <fieldset>
            <legend>
              Commercial Data
            </legend>
            <Input type='text' name='comercial_name' register={register} defaultValue={data.comercial_name} label={'Nombre comercial'} required={true} bgColor='background_login'/>
            <Input type='text' name='comercial_address' register={register} defaultValue={data.comercial_address} label={'Dirección comercial'} required={true} bgColor='background_login' />
            <Input type='text' name='comercial_postal_code' register={register} defaultValue={data.comercial_postal_code} label={t('form.fields.postal_code') as string} required={{
              required: t('form.errors.empty') as string,
              pattern: {
                value: inputPattern.postalCode['GB'],
                message: 'The zip code entered is not valid'
              }
            }} error={formErrors.comercial_postal_code} bgColor='background_login' />
            <Input type='text' name='comercial_province' register={register} defaultValue={data.comercial_province} label={t('form.fields.province') as string} required={true} bgColor='background_login' />
            <Input type='text' name='comercial_city' register={register} defaultValue={data.comercial_city} label={t('form.fields.city') as string} required={true} bgColor='background_login' />
            <Input type='text' name='comercial_phone' register={register} defaultValue={data.comercial_phone} label={t('form.fields.phone') as string} required={true} bgColor='background_login' />
            <Checkbox name='automatic_billing' register={register} label='Facturas de mis comisiones emitidas automáticamente por Virbac' defaultChecked={!data.manual_billing} bgColor='background_login' />
          </fieldset>
          {/* aria-disabled='true' */}
          <fieldset>
            <legend>
              Fiscal data
            </legend>
            <Input type='text' name='fiscal_name' register={register} defaultValue={data.fiscal_name} label={'Nombre fiscal'} bgColor='background_login' required={true} error={formErrors.fiscal_name}/>
            <Input type='text' name='fiscal_address' register={register} defaultValue={data.fiscal_address} label={'Dirección fiscal'} bgColor='background_login' required={true} error={formErrors.fiscal_postal_code} />
            <Input type='text' name='fiscal_postal_code' register={register} defaultValue={data.fiscal_postal_code} label={t('form.fields.postal_code') as string} required={{
              required: t('form.errors.empty') as string,
              pattern: {
                value: inputPattern.postalCode['GB'],
                message: 'The zip code entered is not valid'
              }
            }} bgColor='background_login' error={formErrors.fiscal_postal_code} />
            <Input type='text' name='fiscal_province' register={register} defaultValue={data.fiscal_province} label={t('form.fields.province') as string} bgColor='background_login' required={true} error={formErrors.fiscal_province} />
            <Input type='text' name='fiscal_city' register={register} defaultValue={data.fiscal_city} label={t('form.fields.city') as string} bgColor='background_login' required={true} error={formErrors.fiscal_city} />
            <Input type='text' name='fiscal_cif' register={register} defaultValue={data.fiscal_cif} label='CIF/NIF/NIE'  bgColor='background_login' required={{
              required: true,
              validate: (value) => validate.cifandnif(value) || (t('form.errors.validate_cif') as string)
            }} error={formErrors.fiscal_cif}/>
            <Input type='text' name='fiscal_iban' register={register} defaultValue={data.fiscal_iban} label='IBAN' bgColor='background_login' required={true} />
          </fieldset>
          <fieldset className='fw'>
            <p>
              VIRBAC ESPAÑA, S.A., con domicilio en calle Ángel Guimerá, 179-181, 08950 Esplugues de Llobregat (Barcelona), tratará tus datos personales para gestionar la relación contractual. Tienes derecho a acceder, rectificar y suprimir los datos, así como a otros derechos, tal y como se explica en nuestra <Link href='/politica-de-privacidad' target='_blank'>Política de Privacidad</Link> y en la información contenida en el <Link href='/rgpd' target='_blank'>Aviso Legal</Link> así como en nuestro <Link href='/acuerdo-comercial' target='_blank'>Acuerdo Comercial</Link>.
            </p>
            <Checkbox name='communications_allowed' register={register} label='La cesión de tus datos personales así como los perfiles comerciales realizados, en tu caso, a VIRBAC ESPAÑA S.A., con la finalidad de que podamos enviarte comunicaciones comerciales personalizadas sobre nuestros productos y/o servicios.' defaultChecked={false} bgColor='background_login' required={false}/>
            <Checkbox name='privacy_policy_accepted' register={register} label='He leído y acepto la política de privacidad y la información contenida en el aviso legal y en el acuerdo comercial.' defaultChecked={false} bgColor='background_login' required={true}/>
          </fieldset>
        </>}
        {decodedUser?.onboarding && decodedUser?.role === 'vet_manager' && (step === 0) && <Button type='button' onClick={() => setStep(prev => prev + 1)} disabled={password?.length === 0 || !!formErrors.password}>{t('commons.actions.next')}</Button>}
        {(decodedUser?.role !== 'vet_manager' || !decodedUser?.onboarding || step === 1) && <Button type='submit' disabled={decodedUser?.onboarding && !privacyPolicyAccepted}>{t('commons.actions.confirm')}</Button>}
      </Form>
    </>
  )
}

const Form = styled.form<{ $largeWidth: boolean }>`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / span 6;
  justify-content: space-between;
  max-width: ${({ $largeWidth }) => $largeWidth ? '680px !important' : 'inherit'};
  width: 100%;

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
  }

  legend {
    ${getP20()}
    margin-bottom: ${vw(30, 'mobile')};
    padding: 0 ${vw(4.25, 'mobile')};
    opacity: .6;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(30, 'tablet')};
      padding: 0 ${vw(4.25, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(30, 'desktop')};
      padding: 0 ${vw(12.5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 30px;
      margin-top: 60px;
      padding: 0 12.5px;
    }

    a {
      color: var(--color-blue);
      text-decoration: underline;
    }
  }

  p {
    a {
      text-decoration: underline;
      transition: 200ms color linear;

      &:hover {
        color: var(--color-blue);
      }
    }
  }

  fieldset {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin-top: ${vw(20, 'mobile')};
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(20, 'desktop')};
      width: 50%;
    }

    ${mq.greaterThan('container')} {
      margin-top: 20px;
      width: 45%;
    }

    &.fw {
      ${mq.greaterThan('tablet')} {
        width: 100%;
      }

      ${mq.greaterThan('container')} {
        width: 100%;
      }
    }
  }

  label {
    padding-left: 0;
  }

  button {
    &[type='submit'] {
      margin: 40px 0 20px;
      margin-left: ${vw(4.25, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-left: ${vw(4.25, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin-left: ${vw(12.5, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-left: 12.5px;
      }
    }
  }

  .go-next {
    margin-top: 10px;
  }

  .go-back {
    margin-right: 100%;
    margin-bottom: 50px;
  }

  label {
    transition: 10ms opacity ease-in-out;

    &.hidden {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      user-select: none;
    }
  }
`

'use client'
import { type FC } from 'react'
import styled from 'styled-components'
import { FormAuthLogin } from '../forms/FormAuthLogin'
import { FormAuthSignUp } from '../forms/FormAuthSignUp'
import { FormAuthResetPassword } from '../forms/FormAuthResetPassword'

type Props = {
  formType: 'login' | 'signup' | 'reset-password'
}

const formAuthByType = {
  'login': () => <FormAuthLogin />,
  'signup': () => <FormAuthSignUp />,
  'reset-password': () => <FormAuthResetPassword />
}

export const SectionAuth:FC<Props> = ({ formType = 'login' }) => {

  return <Section>
    {formAuthByType[formType]()}
  </Section>
}

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  hgroup {
    width: 100%;
  }
`
